<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button
        class=""
       variant="primary"
        @click="openModal(null, 'Create')"
        v-if="resolvePermission('settings.editUsers')"
      >
        <feather-icon icon="PlusIcon" size="15"> </feather-icon>
        {{ $t("add new") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col class="col-9 col-md-11">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <b-col class="col-3 col-md-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterItems"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive :items="filteredUsers" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        <p class="text-nowrap m-0">
          {{ data.value }}
        </p>
      </template>
      <template #cell(status)="data">
        <div>
          <b-avatar
            size="32"
            :variant="`light-${data.value ? 'success' : 'danger'}`"
          >
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template>
      <template #cell(actions)="row">
        <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="resolvePermission('settings.editUsers')"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row)"
          variant="flat-danger"
          class="btn-icon"
          v-show="row.item.id != 1"
          v-if="resolvePermission('settings.deleteUsers')"
        >
          <feather-icon icon="Trash2Icon" size="15" />
        </b-button>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BAvatar,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        ,
        {
          key: "name",
          label: this.$t("name"),
        },
        {
          key: "phone",
          label: this.$t("phone"),
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        // {
        //   key: "notes",
        //   label: this.$t("Notes"),
        // },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: "",
      routePermissions: "",
    };
  },
  watch: {
    $route: function () {
      this.routePermissions = [this.$route.meta.userRole];
      this.filterItems();
    },
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.routePermissions = [this.$route.meta.userRole];
    this.getFilteredUsers({
      // permissions: this.routePermissions
    })
      .then((res) => {
        this.rows = res.data.meta.total;
      })
      .catch((err) => console.error(err));
  },

  methods: {
    ...mapActions([
      "getRoles",
      "deleteRole",
      "getPermissions",
      "getFilteredUsers",
      "deleteUser",
    ]),
    filterItems() {
      setTimeout(() => {
        this.getFilteredUsers({
          page: this.currentPage,
          keywords: this.searchText == "" ? undefined : this.searchText,
          // permissions: this.routePermissions,
        })
          .then((res) => {
            this.rows = res.data.meta.total;
          })
          .catch((err) => {});
      }, 100);
    },
    showItem(row) {
      this.openModal(row, "Show");
    },

    editItem(row) {
      this.openModal(row, "Edit");
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteUser, row);
    },
  },

  computed: {
    ...mapGetters(["filteredUsers", "allUsers", "permissions"]),
  },
};
</script>